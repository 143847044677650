<template>
  <div class="app">
    <div class="app-h">投保申请</div>

    <div class="quan">
      <div class="felx">
        <div class="cion-fe">
          <div class="cdion-txt">生成保函合同</div>
          <div :class="kai ? 'cdion-2' : 'cdion-1'">
            <!-- <div class="cdion-2">  <div class="cdion-3">2</div></div> -->
            <!-- <i v-if="kai" class="el-icon-s-order"></i>
                <i v-else class="yanse el-icon-check"></i> -->
            <img
              v-if="kai"
              src="../../../src/assets/imges/jiazai.gif"
              class="yanse"
              alt=""
              srcset=""
            />
            <!-- <i  class=" el-icon-loading"></i> -->
            <i v-else class="yanse el-icon-check"></i>
          </div>
          <div class="wohi-txt" v-if="kai">(加载中...)</div>
          <div class="wohi-txt" v-else></div>
        </div>
        <div v-if="ban" class="felx-1">
          <!-- <div>
                - - -
            </div> -->
          <!-- <div class="dion">▶</div> -->
          <div>
            {{ duan }}
          </div>
        </div>
        <div v-else class="felx-2">
          <div>
            {{ duanl }}
          </div>
          <!-- <div class="dion">▶</div> -->
          <!-- <div>
            {{duan}}
            </div> -->
        </div>
        <div class="cion-fe">
          <div class="cdion-txt">合同签章</div>
          <div :class="ban ? 'cdion-2' : 'cdion-1'">
            <!-- <div class="cdion-2">  <div class="cdion-3">3</div></div> -->
            <!-- <i v-if="ban" class="el-icon-s-check"></i>
                 <i v-else class="yanse el-icon-check"></i> -->
            <img
              v-if="ban"
              src="../../../src/assets/imges/jiazai.gif"
              class="yanse1"
              alt=""
              srcset=""
            />
            <!-- <img v-else src="../../../src/assets/imges/icu3.png" class="a-imgs" alt="" srcset=""> -->
            <i v-else class="yanse el-icon-check"></i>
          </div>
          <div class="wohi-txt" v-if="ban">(加载中...)</div>
          <div class="wohi-txt" v-else></div>
        </div>
        <div v-if="bai" class="felx-1">
          <div>
            {{ duan }}
          </div>
          <!-- <div class="dion">▶</div> -->
          <!-- <div>
                 {{duan}}
            </div> -->
        </div>

        <div v-else class="felx-2">
          <div>
            {{ duanl }}
          </div>
          <!-- <div class="dion">▶</div> -->
          <!-- <div>
                 {{duanl}}
            </div> -->
        </div>
        <div class="cion-fe">
          <div class="cdion-txt">合同下载</div>
          <div :class="bai ? 'cdion-2' : 'cdion-1'">
            <!-- <div class="cdion-2">  <div class="cdion-3">4</div></div> -->
            <!-- <i v-if="bai" class="yanse el-icon-refresh"></i> -->
            <!-- <img v-else src="../../../src/assets/imges/icu4.png" class="a-imgs" alt="" srcset=""> -->
            <img
              v-if="bai"
              src="../../../src/assets/imges/jiazai.gif"
              class="yanse1"
              alt=""
              srcset=""
            />
            <i v-else class="yanse el-icon-check"></i>
          </div>
          <div class="wohi-txt" v-if="bai">(加载中...)</div>
          <div class="wohi-txt" v-else></div>
        </div>
      </div>
      <div>
        <div v-if="!tong" class="cion-bu" type="primary" @click="project">
          {{ tongbu }}
        </div>
        <div v-else class="cion-bu1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { bhtypelist, baohannotice, bhfileState } from "@/api/user";
import { createcontract, filesign, bhfiledown } from "@/api/user";
export default {
  data() {
    return {
      input: {
        baohanno: "",
        unify_code: "",
        zfimage: "",
        bankname: "",
        bankcode: "",
      },
      yi: true,
      kai: true,
      ban: true,
      bai: true,
      quan: false,
      duan: "- - - - -",
      duanl: "———",
      active: 0,
      bankaccount: "",
      tong: true,
      tongbu: "同步资料完成",
      tabPosition: "right",
      tab: "left",
      dialogImageUrl: "",
      cion: "",
      dialogVisible: false,
      unify_code: "",
      applyno: "",
      codeion:1,
      message:"",
    };
  },
  async created() {
    // this.tiao()
     this.applyno = sessionStorage.getItem("applyno");
    this.input.baohanno = sessionStorage.getItem("baohanno");
    var apps=this.applyno
    // var apps = "TBaoHanHNYZTBBH2022072717222050537";
    const { data } = await bhfileState(apps);//获取状态值判断是否需要重新同步
    // console.log(data.msg);
    if (data.msg == "第一次文件下载成功") {
      // this.entryUserInfo();
      this.kai = false;
      this.ban = false;
      this.bai = false;
      var that = this;
        this.baohannotices()
      setTimeout(function () {
        // 异步，延迟执行
        that.tong = false;
      }, 1000);
    } else {
      this.entryUserInfo();
    }
  },
  methods: {
    async bhtypelist() {
      var bbc = this.unify_code;
      const { data } = await bhtypelist(bbc);
      this.cion = data.data;
    },
    entryUserInfo() {
      this.quan = true;
      var that = this;
      this.active=0
// 进行三步走
      for (var i = 0; i < 5; i++) {
        setTimeout(function () {
          // 异步，延迟执行
          // console.log(123);
          that.next(123);
        }, i * 6000);
      }
    },
    // 三步
    async next(e) {
      var bbc = this.unify_code;
      var cion = this.input.baohanno;
      var app = this.applyno;
      var tijiao = {
        applyno: app,
        type: 1,
      };
      // console.log(e);
      this.active = this.active + 33;
      if (this.active <= 33) {
  // console.log("申请");
        this.kai = false;
        const { data } = await createcontract(tijiao);// 申请
        // console.log(data);
        return;
      }
      if (this.active <= 66) {
      //  console.log("申请");
        this.ban = false;
        const { data } = await filesign(tijiao);// 合成
        // console.log(data);
        return;
      }
      if (this.active <= 99) {
          // console.log("申请");
        this.bai = false;
        var that = this;
        const { data } = await bhfiledown(tijiao);// 下载
        var bion = data.data + "";
        console.log("cun");
        setTimeout(function () {
          // 异步，延迟执行
          that.bhfilezt();
        }, 1000);
        // sessionStorage.setItem(bion,value)
        // this.tongbu = "同步资料完成";
        return;
      }
    },
        async bhfilezt() {
      var that = this;
      var apps=this.applyno
      // var apps = "TBaoHanHNYZTBBH2022072717222050537";
      const { data } = await bhfileState(apps);//合同下载后判断是否成功

      if (data.msg == "第一次文件下载成功") {

        this.tong = false; //显示同步按钮
        this.baohannotices()
      } else {
        this.$notify({
          title: "提醒",
          message: "同步资料失败。",
        });
        this.tongbu = "重新获取";
        this.tong = false;
      }
    },
    async baohannotices() {
      // console.log(123);
      var bbc = this.applyno;
      const { data } = await baohannotice(bbc);
      console.log(data.code);
     this.codeion=data.code
  this.message=data.message
      // console.log(data);
      // this.$notify({
      //   title: "提醒",
      //   message: "投保申请结果通知成功!",
      // });
    },

    project() {
      var bao = this.input.baohanno;
      var app = this.applyno;
      if (this.tongbu == "重新获取") {
        // console.log(123);
      this.kai = true;
      this.ban = true;
      this.bai = true;
      this.tong=false;
      this.entryUserInfo()
      } else {
        // console.log(this.codeion===0 || this.codeion==undefined);
        if(this.codeion==0){
        return    this.$alert(this.message, '', {
          confirmButtonText: '确定',
  
        });
        }else{
          console.log("正确");
         return    this.$router.push({
          name: "public",
          params: {
            bao,
            app,
          },
        });
        }
     
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    chengg(response, file, fileList) {
      this.input.zfimage = response.data.url;
    },
  },
};
</script>

<style scoped>
html {
  width: 100vw;
}
.quan {
  /* background: rgb(128, 128, 128, 1); */
  position: fixed;
  top: -160px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cdion-2 {
  position: relative;
}
.cdion-3 {
  position: absolute;
  font-size: 12px;
  top: -25px;
  left: 9px;
}
.cion-fe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app {
  /* padding: 0 20%; */
}
/deep/ .el-steps--vertical {
  height: 80%;
}
.el-icon-loading {
  font-size: 1.25rem;
}
.cion-bu {
  margin-top: 5rem;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
  width: 12.5rem;
  border-radius: 11px;
  background: #069cff;
  color: #fff;
}
.cion-bu1 {
  margin-top: 5rem;
  height: 40px;
  line-height: 40px;
  width: 12.5rem;
}
.felx {
  display: flex;
}
.felx-1 {
  padding: 0 0.3125rem;
  height: 5rem;
  display: flex;
  line-height: 8rem;
  font-size: 1.875rem;

  /* align-items:center; */
  color: rgb(170, 167, 167);
}
.felx-2 {
  padding: 0 0.3125rem;
  height: 5rem;
  display: flex;
  line-height: 8rem;
  font-size: 1.875rem;
  font-weight: 900;
  /* align-items:center; */
  color: #069cff;
}
.yanse {
  /* padding-left: 3px; */
  padding-top: 0.2rem;
  /* width: 1.5125rem; */
  height: 1.5125rem;
  /* color: #0a308f; */
}
.yanse1 {
  margin-top: 3px;
  width: 2.5125rem;
  height: 2.5125rem;
}
.cdion-txt {
  font-size: 1rem;
  margin-bottom: 20px;
}
.dion {
  line-height: 5.3125rem;
  font-size: 0.9375rem;
  padding: 0rem 5px;
}
.a-imgs {
  padding-top: 1.4rem;
  width: 1.5125rem;
  height: 1.5125rem;
}
.namei {
  margin-top: 0.625rem;
  height: 20%;
  margin-left: 70%;

  /* height: 100%;
    margin-left: 70%; */
}
/* /deep/ .el-step__icon.is-icon{
    border-radius: 50%;
    border: .125rem solid;
    border-color: inherit;
    w
} */
.app-xt {
  padding: 0 8%;
}
.cdion-1 {
  width: 3rem;
  height: 3rem;
  font-size: 31px;
  text-align: center;
  line-height: 3rem;
  color: #fff;
  background: #069cff;
  border: 2px solid #069cff;
  margin-bottom: 1.25rem;
  border-radius: 20rem;
  /* background-image:url("../../../src/assets/imges/1fff.png"); */
  /* background-size: 100%,100%; */
}
.cdion-2 {
  width: 3rem;
  height: 3rem;
  font-size: 31px;
  text-align: center;
  line-height: 3rem;
  border: 2px solid #f6f7f8;
  color: #fff;
  background: #f6f7f8;
  margin-bottom: 1.25rem;
  border-radius: 20rem;
}
.b-bbc {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.indec {
  display: inline-block;
  width: 26.25rem;
  padding: 3.125rem;
  margin-top: -15%;
  height: 35%;
  padding-bottom: 0.625rem;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 0.0625rem solid #ebeef5;
  font-size: 1.125rem;
  box-shadow: 0 0.125rem 0.75rem 0rgba (0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding-bottom: 1.875rem;

  /* display: flex;
  flex-direction: column;
    justify-content: space-between; */
}
.name {
  margin-bottom: 1.25rem;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-jin {
  width: 100%;
  height: 100%;
  background: #dedede;
}
.uppder {
  padding-right: 0.75rem;
}
.input-flex {
  display: flex;
  margin-top: 0.625rem;
}
.input-1 {
  width: 12.5rem !important;
  width: 7.0625rem;
  /* height: 1.25rem !important; */
}
.app-h {
  padding-left: 27px;
  width: 100%;
  height: 43px;
  line-height: 43px;
  background: #1890ff;
  font-size: 17px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner {
  margin-left: 0.875rem !important;
  height: 1.6875rem !important;
}

.text {
  font-size: 0.875rem;
}

.item {
  padding: 0.5rem 0;
}

.box-card {
  width: 30rem;
}
.flex-txt {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff0101;
}
.flex-txt1 {
  font-size: 0.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 6.25rem;
  text-align: right;
}
.abc {
  padding-left: 1.5rem;
}
.app-a {
  display: flex;
  margin-top: 28px;
  position: relative;
}
.a-left {
  width: 50%;
  height: 49px;
  display: flex;
  line-height: 50px;
  align-content: center;
  justify-content: center;
  color: #069cff !important;
  background: #cdebff;
  border: 1px solid #f0f3fb;
  border-right: 0;
  border-radius: 9px 0px 0px 9px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.a-img {
  padding-top: 13.6px;
  padding-right: 15px;
  width: 20px;
  height: 20px;
}
.aa-img {
  position: absolute;
  height: 3.06rem;
  margin-top: 1px;
  padding: 0;
  left: 45%;
}
.a-imgs {
}
.a-right {
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 50px;
  width: 50%;
  height: 49px;
  margin-top: 0.0625rem;

  background: #069cff;
  border-radius: 7px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.app-b {
  font-size: 0.5625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 45px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  padding: 0 30%;
  /* align-content: space-around;
  justify-content: space-between; */
}
.app-c {
  width: 100%;
  margin-top: 30px;
}
.wohi-txt {
  font-size: 12px;
  padding-left: 12px;
}
.app-b2 {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  width: 48%;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 26px;
}
.b-txt {
  width: 296px;
  height: 24px;
  flex: 1;
  background: #f3f3f3;
  border: 1px solid #dedede;
  padding-left: 6px;
  line-height: 24px;
  font-size: 0.8125rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.c-img {
  width: 30%;
  height: 100%;
}
.c-txt {
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.txt-1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-2 {
  margin-top: 17px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-3 {
  margin-top: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.txt-4 {
  margin-top: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.c-div {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
}
.butt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-i {
  margin-top: 48px;
  width: 20%;
  height: 5.5%;
  padding: 5px 0;
  line-height: 30px;
  border-radius: 20px;
  background: #069cff;
  text-align: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fefefe;
}
/* 上传 */
</style>